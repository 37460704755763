@import '../../styles/colors.scss';

.instant-price-small-progress-container {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (min-width: 960px) {
    display: flex;
  }

  h3 {
    margin: 0;
    color: $lightgrey;
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 24px;
    letter-spacing: 0.5px;
    font-weight: 700;
  }
}

.instant-price-small-progress {
  background-color: rgba($color: $primary, $alpha: 0.25);
  height: 8px;
  width: 130px;
}

.instant-price-small-progress__filled {
  background-color: $primary;
  width: 10%;
  height: 8px;

  transition: all 0.3s ease;
}
