@import '../../styles/colors';

footer {
  padding: 100px 0 100px;
}

.footer-links-area {
  display: flex;
}

.footer-links {
  padding: 0;
  margin-top: 0;
  list-style: none;
  margin-right: 64px;

  &:last-child {
    margin-right: 0;
  }

  li {
    margin-bottom: 1.6em;
  }

  a {
    font-family: 'Gotham', sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: $primary;
    text-decoration: none;
  }

  @media (max-width: 960px) {
    width: 30%;
  }

  @media (max-width: 640px) {
    width: 50%;
  }
}

.copyright-area {
  color: $lightgrey;
  text-transform: uppercase;

  p {
    margin-top: 30px;
    margin-bottom: 0;
  }

  p,
  a {
    font-family: 'Gotham', sans-serif;

    @media (max-width: 640px) {
      font-size: 13px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  display: flex;
  justify-content: space-between;
}

.footer-btn-area {
  margin-bottom: 80px;
}

.footer-btn {
  text-decoration: none;
  height: 72px;
  // max-width: 345px;

  @media (max-width: 1200px) {
    max-width: 100%;
    width: 100%;
  }

  a {
    text-decoration: none;
    box-sizing: border-box;
    // position: absolute;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // top: 0;
    background-color: #f3f5f7;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      background-color: darken(#f3f5f7, 5%);
    }

    @media (max-width: 740px) {
      padding: 0 0 0 16px;
    }

    @media (max-width: 600px) {
      width: 100%;
      max-width: 100%;
      padding: 16px;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 24px;
    fill: $primary;

    @media (max-width: 740px) {
      width: 20px;
      height: 20px;
      margin-right: 14px;
    }
  }

  p {
    padding: 0;
    margin: 0;
    font-family: 'Gotham', sans-serif;
    font-weight: bold;
    font-size: 17px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: left;
    letter-spacing: 0.5px;

    @media (max-width: 800px) {
      font-size: 14px;
    }
  }

  p:first-child {
    color: $lightgrey;
  }

  p:last-child {
    color: $primary;
  }

  text-transform: uppercase;
}
