@import '../../styles/colors.scss';

.card {
  position: relative;
  box-shadow: none !important;
  padding: 32px 16px 16px;
  // max-width: 354px;
  // max-height: 354px;
  border-radius: 0;

  // width: 100%;
}

.FancyInput {
  cursor: pointer;

  &.MuiPaper-root {
    background-color: #f3f5f7;
  }

  display: flex;
  flex-direction: column;

  align-items: center;

  justify-content: space-between;
  border: 2px solid transparent;
  transition: all 0.5s ease;
  border-radius: 0;
}

.FancyInput--selected {
  border: 3px solid $primary;
}

.FancyInput__Text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  color: #fff;
  position: relative;
  z-index: 30;
  margin-top: 20px;

  transition: all 0.2s ease;

  .title--medium {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
  }

  .subtitle {
    margin-top: 6px;
    margin-bottom: 0;
    font-weight: normal;
    color: #9b9b9b;
    font-size: 14px;
    text-align: center;
  }
}

.FancyInput--FillOnSelected {
  img {
    z-index: 20;
  }

  .FancyInput__Text {
    z-index: 20;
    position: relative;
  }
}

.FancyInput--Image img {
  width: 100%;
  position: relative;
  z-index: 30;
  max-width: 100%;
}

.FancyInput--Image svg {
  // width: 100%;
  position: relative;
  z-index: 30;
  max-width: 100%;
  max-height: 200px;
  margin: 0 auto;
}

.FancyInput--FillFullBackground {
  position: absolute;
  opacity: 0;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $secondary;
  height: 100%;
  width: 100%;
  transition: all 0.17s ease;
}

.FancyInput--FillFullOnSelected {
  .FancyInput__Text {
    text-align: center;
    justify-content: center;

    > div {
      text-align: center;
      // width: 100%;
    }
  }

  .FancyInput__Text__checkmark {
    display: none;
  }
}

.FancyInput--RoofingImages,
.FancyInput--ForcedAirPowerImages {
  .title {
    font-size: 18px;
    line-height: 23px;
  }
}

.FancyInput--RoofingImages,
.FancyInput--ForcedAirPowerImages {
  .img-selected {
    display: none;
  }
}

.FancyInput--RoofingImages,
.FancyInput--ForcedAirPowerImages {
  input:checked ~ img.img-selected {
    display: block;
  }
}

.FancyInput--RoofingImages.FancyInput--Image img {
  width: 100px;
  margin: 64px auto 32px;
}

.FancyInput--RoofingImagesQuestionMark.FancyInput--Image img {
  width: 80px;
  margin: 64px auto 32px;
}

.FancyInput--ForcedAirPowerImages.FancyInput--Image img {
  width: 42px;
  margin: 64px auto 32px;
}

.FancyInput--ForcedAirPowerImagesQuestionMark.FancyInput--Image img {
  width: 72px;
  margin: 64px auto 32px;
}
