@import '../../styles/colors.scss';

.main-navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 80px;
  z-index: 10000;
  display: flex;
  align-items: center;
  z-index: 9999;
  background: #fff;

  img {
    cursor: pointer;
  }

  &.has-banner {
    top: 93px;

    @media (min-width: 410px) {
      top: 73px;
    }

    @media (min-width: 620px) {
      top: 73px;
    }

    @media (min-width: 1001px) {
      top: 53px;
    }
  }

  @media (min-width: 1024px) {
    height: 90px;
    background: #fff;

    // padding: 0px 16px 0px;
  }

  @media (max-width: 1024px) {
    &.main-navigation--landing {
      background: $primary;
    }
  }

  &.scrolled {
    background: rgba($color: #fff, $alpha: 0.96);
    // padding-top: 24px;
    margin-top: 0;

    @media (min-width: 1024px) {
      .btn-link {
        display: flex;
      }
    }
  }

  > .MuiContainer-root {
    @media (min-width: 1024px) {
      margin-top: 0;
      display: flex;
      align-items: center;
    }
  }

  .btn-primary {
    &.MuiButton-contained {
      height: 48px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.btn-link {
  display: none;
}

.mobile-icon {
  position: absolute;
  animation: spin 100ms ease 200ms;
}

body.mobile-nav-open {
  .mobile-icon--open {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  .mobile-icon--closed {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}

.mobile-icon--open {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  animation-fill-mode: forwards;
}

.mobile-icon--closed {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  animation-fill-mode: forwards;
}

body.mobile-nav-open {
  overflow: hidden;
}

.mobile-nav-open .mobile-navigation {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.mobile-navigation {
  background: #fff;
  overflow-y: auto;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  z-index: 9999;

  position: fixed;
  width: 100%;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;

  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  nav {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  nav .nav-link {
    font-size: 36px;
    font-weight: bold;
    line-height: 54px;
    color: $primary;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      color: lighten($color: #242423, $amount: 30);
    }
  }
}

.mobile-navigation__signin-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  // font-size: 16px;
  font-weight: normal;
  color: #9b9b9b;
  cursor: pointer;
  margin-bottom: 36px;

  a {
    text-decoration: none;
    display: flex;

    .MuiSvgIcon-root {
      transition: all 0.25s ease;
      margin-top: -2px;
    }

    &:hover {
      // color: #242423;

      // svg {
      //   width: 24px;
      //   height: 24px;
      //   fill: #242423;
      // }
    }
  }

  &.no-margin-bottom {
    margin-bottom: 0;
  }

  &.desktop-nav {
    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
      margin-top: 0;
    }
  }
}

.mobile-logo--white {
  margin-top: 8px;

  @media (min-width: 1024px) {
    display: none;
  }
}

.main-navigation:not(.main-navigation--landing) {
  .mobile-logo--white {
    display: none;
  }
}

.logo {
  margin-top: 12px;
  width: 225px;

  @media (max-width: 960px) {
    width: 173px;
  }

  @media (min-width: 1024px) {
    display: flex !important;
  }
}

.main-navigation.main-navigation--landing {
  .logo {
    display: none;
  }
}

@media (min-width: 1024px) {
  .main-navigation__left,
  .main-navigation__middle,
  .main-navigation__right {
    display: flex;
    align-items: center;
  }
}

.scrolled.main-navigation--landing {
  .mobile-logo--white {
    display: none;
  }

  .logo {
    display: flex;
  }
}

.main-navigation:not(.main-navigation--landing),
.scrolled.main-navigation:not(.main-navigation--landing) {
  .mobile-logo {
    display: flex;
  }
}

.main-navigation__left {
  flex: 2;
  display: flex;
  justify-content: flex-start;
}

.main-navigation__middle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  img {
    width: 225px;
  }

  @media (max-width: 1023px) {
    display: none;
  }
}

.main-navigation__right__items {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.main-navigation__left a {
  text-align: left;
  color: #242423;
  padding: 0 6px;
  margin: 0 6px;

  &:first-child {
    padding-left: 0;
    margin-left: 0;
  }
}

.main-navigation__right a {
  color: #242423;
  padding: 0 6px;
  margin: 0 6px;

  &:last-child:not(:only-child) {
    padding-right: 0;
    margin-right: 0;
  }
}

.main-navigation__right__items,
.main-navigation__left__items {
  a {
    font-family: 'Gotham', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
    color: $primary;
    font-weight: 500;
    cursor: pointer;
  }

  // @media (min-width: 1200px) {
  //   a {
  //     font-size: 18px;
  //   }
  // }

  @media (max-width: 1023px) {
    display: none;
  }
}

.main-navigation__left__items {
  // margin-left: 15%;
  margin-left: 50px;

  a {
    color: $primary;
    // margin-top: 1.1em;
    display: inline-block;
  }
}

.main-navigation__right__items {
  a {
    font-size: 15px;
  }

  svg {
    height: 24px;
    width: 24px;
  }
  //MOBILE MENU
  @media (max-width: 1024px) {
    display: flex;
    justify-content: flex-end;

    .text {
      display: none;
    }
  }
}

.main-navigation--landing {
  @media (max-width: 1024px) {
    svg {
      fill: #fff;
    }
  }
}

.scrolled.main-navigation--landing {
  @media (max-width: 1024px) {
    svg {
      fill: $primary;
    }
  }
}

.main-navigation__right__items {
  @media (max-width: 1024px) {
    .mobile-navigation__phone-link .text {
      display: none;
    }
  }
}

.scrolled .main-navigation__right__items {
  @media (max-width: 1024px) {
    svg {
      fill: $primary;
    }
  }
}

.main-navigation__burger svg {
  fill: #242423;
  color: #242423;
}

.mobile-navigation__phone-link {
  font-size: 14px;
  text-decoration: none;
  color: #9b9b9b;
  line-height: 21px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  font-weight: normal;

  svg {
    width: 22px;
    height: 22px;
  }
  @media (max-width: 1024px) {
    // margin-right: -35px !important;
  }
}

.main-navigation__burger {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // margin-right: -8px;
  //
  color: #fff;

  svg {
    fill: #fff;
  }

  //MOBILE MENU
  @media (min-width: 0px) {
    display: none;
  }
}

// MOBILE MENU CODE
// @media (min-width: 1024px) and (max-width: 1220px) {
//   .sign-in-desktop .text,
//   .mobile-navigation__phone-link .text {
//     display: none;
//   }
// }

// .main-navigation.main-navigation--instant-price {
//   .sign-in-desktop .text,
//   .mobile-navigation__phone-link .text {
//     display: block;
//   }
// }
