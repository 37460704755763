@import '../../styles/colors.scss';

.home-intro {
  display: flex;
  flex-direction: column;

  @media (min-width: 900px) {
    flex-direction: row;
    min-height: 715px;
  }
}

.home-intro__text {
  position: relative;

  z-index: 200;
  box-sizing: border-box;
  background-color: $primary;
  color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding: 24px 0 100px;
  padding-top: 24px;
  padding-bottom: 100px;

  h1 {
    font-size: 48px;
    line-height: 53px;
    margin-top: 0;
    margin-bottom: 24px;
    letter-spacing: 0.2px;

    @media (max-width: 960px) {
      font-size: 38px;
      line-height: 43px;
    }

    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 41px;
    }
  }

  .subtitle {
    font-weight: 500;
    margin-top: 0;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 50px;
    letter-spacing: 0.2px;

    @media (max-width: 960px) {
      font-size: 20px;
      line-height: 25px;
    }

    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 40px;
    }
  }

  .btn {
    align-self: flex-start;
    height: 56px;

    // > span {
    //   position: relative;
    //   top: 1px;
    // }

    // @media (max-width: 960px) {
    //   font-size: 38px;
    //   line-height: 43px;
    // }

    @media (max-width: 640px) {
      font-size: 14px;
      width: 100%;
    }
  }

  @media (min-width: 0px) and (max-width: 640px) {
    padding-bottom: 50px;
  }

  @media (min-width: 900px) {
    width: 50%;
    padding: 0 5%;
  }
}

.home-container {
  @media (max-width: 900px) {
    background-color: $primary;
    padding-bottom: 66%;
  }
}

.home-intro__text__overflow {
  width: 100vh;
  height: 100%;

  position: absolute;
  left: -100vh;
  top: 0;
  z-index: -1;

  background-color: $primary;

  @media (max-width: 900px) {
    display: none;
  }
}

.home-intro__image {
  // width: 10%;
  width: 100%;
  height: auto;
  background-image: url('../../assets/images/lawn-doctors-home-intro.jpg');
  background-size: cover;
  background-position: center;

  @media (min-width: 900px) {
    width: 50%;
    height: auto;
  }
}

.home-intro__image--mobile-container {
  margin: 0 16px;
  margin-top: -66%;
  display: block;

  @media (min-width: 900px) {
    display: none;
  }
}

.home-intro__image--mobile {
  z-index: 100;
  max-width: 100%;
  width: 100%;
}

.section--locally-owned {
  padding-top: 100px;
  padding-bottom: 133px;

  @media (max-width: 640px) {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}

.certifications-area {
  margin-top: 64px;

  @media (max-width: 640px) {
    margin-top: 40px;
  }
}

.certification-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;

  h4 {
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 0.5px;

    @media (max-width: 640px) {
      font-size: 14px;
    }
  }
}

.certification-item--bbb {
  h4 {
    margin-top: 8px;
  }

  @media screen and (max-width: 960px) {
    h4 {
      margin-top: 5px;
    }
  }

  @media screen and (max-width: 640px) {
    h4 {
      margin-top: 0;
    }
  }
}

.certification-item--homestars {
  margin-top: 20px;

  @media screen and (max-width: 490px) {
    img {
      width: 150px;
    }

    // img {
    //   margin-top: 0px;
    // }
  }
}

.bbb-icon {
  width: 60px;
}

.homestars-icon {
  width: 170px;

  @media (max-width: 640px) {
    width: 130px;
  }
}

.google-icon {
  @media (max-width: 640px) {
    width: 130px;
  }
}

.guarantee-icon {
  width: 60px;
}

.service-item {
  margin-top: 100px;
  margin-bottom: 100px;

  @media (max-width: 640px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.service-item--one,
.service-item--three {
  @media (max-width: 640px) {
    .MuiGrid-container .MuiGrid-item:first-child {
      display: flex;
      order: 2;
    }

    .MuiGrid-container .MuiGrid-item:last-child {
      display: flex;
      order: 1;
    }
  }
}

.services-list-container {
  display: flex;
  flex: 1;
  justify-content: space-between;

  @media (max-width: 800px) {
    justify-content: space-around;
  }
}

.section--services-list {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  text-align: center;

  svg {
    // max-width: 350px;
    height: 224px;
    width: 100%;
  }

  h1 {
    margin-bottom: 0;
    // margin-top: 40px;
  }

  p {
    margin: 0;
  }
}

.services-list-item {
  margin-top: 100px;
  display: flex;
  flex: 1 1 28.33%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 28.33%;
  padding: 0 2.33%;
  @media (max-width: 800px) {
    flex: 1 1 47%;
    width: 47%;
    padding: 0 1.5%;
  }

  @media (max-width: 640px) {
    flex: 1 1 auto;
    width: 100%;
    padding: 0;
  }

  h1 {
    margin-bottom: 0;
  }

  p {
    margin-top: 10px;
  }

  &:first-child {
    svg {
      height: 220px;
    }

    h1 {
      margin-top: 20px;
    }
  }
}

.section--good-company {
  margin: 150px 0 0;
  padding: 150px 0 150px;
  background-color: rgba($color: $lightclay, $alpha: 0.1);

  .title--medium {
    // padding: 0 16px;
  }

  @media (max-width: 640px) {
    padding: 100px 0;
    margin: 100px 0;

    .MuiGrid-item:not(:last-child) .title--medium {
      margin-bottom: 50px;
    }
  }
}

.good-company-text-container {
  margin-top: 50px;

  @media (min-width: 640px) {
    margin-top: 70px;
  }
}

.good-company-thumb {
  width: 182px;
  margin: 0 0 50px;
  display: block;

  @media (min-width: 640px) {
    margin: 0 auto 50px;
  }
}

.section--faq {
  padding: 100px 0;

  @media (max-width: 640px) {
    padding: 72px 0 72px;
  }
}

.section--reviews {
  margin-top: 0;
  padding: 150px 0;
  background-image: url('../../assets//images/lawn-doctors-reviews-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;

  .title {
    margin-top: 0;
    margin-bottom: 24px;
  }

  .title--medium {
    margin-bottom: 0;

    @media (max-width: 960px) {
      background-image: none;
      color: $lightgrey;
    }
  }

  .link-with-icon {
    display: none;
  }

  @media (max-width: 1100px) {
    background-position: 50%;
  }

  @media (max-width: 960px) {
    background-image: none;
    color: $body;
  }

  @media (max-width: 960px) {
    padding: 0;

    .link-with-icon {
      display: flex;
      justify-content: center;
    }
  }
}

.review-area--mobile-image {
  display: flex;

  @media (min-width: 640px) {
    display: none;
  }
}

.review-area--tablet-image {
  display: flex;

  @media (max-width: 640px) {
    display: none;
  }

  @media (min-width: 960px) {
    display: none;
  }
}

.review-certifications {
  display: flex;
  margin-top: 70px;

  @media (min-width: 960px) {
    display: none !important;
  }

  @media (max-width: 640px) {
    margin-top: 50px;
    justify-content: space-around;
  }

  .homestars-icon {
    width: 130px;
  }

  .google-icon {
    width: 170px;
  }

  @media (max-width: 640px) {
    .homestars-icon {
      width: 105px;
    }

    .google-icon {
      width: 134px;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.review-container {
  margin-top: 80px;

  @media (max-width: 640px) {
    margin-top: 40px;
  }
}

h1.review-item__review {
  font-family: 'Sentinel', serif;
  font-style: italic;
  font-weight: 600;

  font-size: 36px;
  line-height: 41px;

  @media (max-width: 960px) {
    font-size: 28px;
    line-height: 33px;
    color: $primary;
    text-align: center;
  }

  @media (max-width: 640px) {
    font-size: 19px;
    line-height: 24px;
  }
}

.review-item__review-by {
  margin-top: 32px;

  text-transform: uppercase;

  font-family: 'Gotham', sans-serif;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.5px;

  @media (max-width: 640px) {
    margin-top: 0;
    color: $primary;
    font-size: 14px;
    line-height: 19px;

    text-align: center;

    span {
      color: $body;
    }
  }
}

.section--cta {
  padding: 150px 0;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  background-color: rgba($lightclay, 0.1);

  @media (max-width: 640px) {
    padding: 75px 0;
  }

  .title {
    margin-top: 0;
  }

  .subtitle {
    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  .cta-bottom p {
    color: $lightgrey;
  }

  .cta-bottom__links {
    a,
    svg {
      color: $primary;
    }
  }

  .btn {
    height: 56px;
  }
}


.section--custom {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;

  @media (max-width: 640px) {

  }

  .title {
    margin-top: 0;
  }

  .subtitle {
    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  .cta-bottom p {
    color: $lightgrey;
  }

  .cta-bottom__links {
    a,
    svg {
      color: $primary;
    }
  }

  .btn {
    height: 56px;
  }
}