@import '../../styles/colors.scss';

.quote-avatar.MuiAvatar-root {
  width: 200px;
  height: 200px;

  @media (max-width: 768px) {
    width: 180px;
    height: 180px;
  }
}

.breakdown-payment-method-switch {
  .MuiChip-clickable {
    font-size: 1em;
    font-family: 'Gotham', sans-serif;
    padding: 8px 16px;
    font-weight: 500;
    border-radius: 0;
    height: 48px;
    margin-top: 24px;
    margin-bottom: 24px;

    background-color: rgba($color: $lightgrey, $alpha: 0.25);
    color: $lightgrey;
    text-transform: uppercase;

    // .MuiChip-label {
    //   position: relative;
    //   top: -1px;
    // }

    &.selected {
      background: #000;
      color: #fff;
    }
  }
}

.quote-breakdown__list {
  color: $lightgrey;
  padding-left: 0;
}

.quote-breakdown__list__item {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  font-family: 'Gotham', sans-serif;
  text-transform: uppercase;

  &:last-child {
    border-top: 3px solid rgba(155, 155, 155, 0.25);
    padding-top: 16px;
    font-weight: 500;
  }
}

.section-what-next {
  margin-top: 104px;
  padding: 48px 0;
  background: rgba($color: $lightclay, $alpha: 0.15);

  .cta-bottom--big-area {
    padding-bottom: 50px;
  }
}

.section--estimate {
  // padding-bottom: 50px;
}

// * {
//   outline: 1px solid red;
// }
