@import '../../styles/colors.scss';

.instant-price-form {
  @media (min-width: 768px) {
    margin-top: 100px;
  }

  @media (min-width: 1024px) {
    margin-top: 200px;
  }
}

.step-title {
  margin-bottom: 24px;
}

.step-subtitle {
  margin-top: 0;
  margin-bottom: 0;
}

.instant-quote__previous-link {
  font-family: 'Gotham', sans-serif;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  vertical-align: middle;
  padding-left: 8px;
  padding-right: 16px;
  margin-top: 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: $lightgrey;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.2s ease;

  &.animate {
    &:hover {
      color: #000;
      svg {
        fill: #000;
        transform: translateX(-8px);
      }
    }
  }

  &.instant-quote__previous-link--fixed-bottom {
    position: fixed;
    width: 100%;
    left: 50%;
    bottom: 40px;
    right: 0;
    transform: translateX(-50%);
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  // svg {
  //   margin-right: 8px;
  //   width: 18px;
  //   height: 18px;
  //   color: #9b9b9b;
  //   transition: all 0.25s ease;
  // }

  &.resend-email-link {
    &:hover {
      svg {
        fill: #000;
      }
    }

    svg {
      margin-left: 8px;
      margin-right: 0;
      width: 24px;
      height: 24px;
    }
  }
}

.fancy-input-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 100px;

  @media (max-width: 640px) {
    margin: 40px auto 0;
    width: 100%;
    justify-content: center;
    margin-top: 75px;
  }
}

.fancy-input-container--space-around {
  justify-content: space-around;
}

.fancy-input-container--center {
  justify-content: center;
}

.step-title {
  @media (max-width: 640px) {
    margin-top: 50px;
    margin-bottom: 40px;
  }
}
.step-subtitle {
  @media (max-width: 640px) {
    margin-top: 20px;
  }
}

.FancyInput {
  margin-bottom: 1rem;

  margin: 0 0 2rem;
  flex: 1 1 calc(100%);
  max-width: calc(100%);

  @media (min-width: 533px) {
    // width: 100%;
    margin: 0 1rem 2rem;
    flex: 1 1 calc(50% - 5em);
    max-width: calc(50% - 5em);

    .card.MuiCard-root {
      > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
    }
  }

  @media (min-width: 980px) {
    // width: 100%;
    margin: 0 1rem 2rem;
    flex: 1 1 calc(33.333334% - 5em);
    max-width: calc(33.333334% - 5em);

    .card.MuiCard-root {
      > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
  }
}

.fancy-input-container--two-cols {
  .FancyInput {
    margin-bottom: 1rem;

    @media (min-width: 980px) {
      // width: 100%;
      margin: 0 1rem 2rem;
      flex: 1 1 calc(50% - 5em);
      max-width: calc(50% - 5em);

      .card.MuiCard-root {
        > div {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
      }
    }
  }
}

.field {
  font-family: 'Gotham', sans-serif;
}

.disclaimer {
  font-size: 12px;
  font-family: 'Gotham', sans-serif;
  line-height: 17px;
  color: $lightgrey;
}
