@import '../../styles/colors.scss';

.instant-quote__progress {
  background-color: $secondary;
  height: 8px;
  width: 100%;

  position: absolute;

  left: 0;
  right: 0;
  bottom: -8px;
  z-index: 1;

  display: none;

  @media (max-width: 960px) {
    display: block;
  }
}

.instant-quote__progress__filled {
  background-color: $primary;
  width: 10%;
  height: 8px;

  transition: all 0.3s ease;
}
