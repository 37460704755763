@import './colors.scss';

.btn {
  .MuiCircularProgress-root {
    position: absolute;
  }

  .MuiButton-label {
    position: relative;
    top: 2px;
  }
}

.btn .MuiCircularProgress-svg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  .MuiCircularProgress-circle {
    stroke: #fff;
  }
}

.btn-primary {
  font-weight: 500;
  text-decoration: none;

  &.MuiButton-contained {
    color: $body;

    background: $highlight;

    box-shadow: none;
    font-family: Gotham, sans-serif;
    text-transform: uppercase;
    font-size: 1em;
    border-radius: 0;
    font-weight: 500;
    padding: 8px 24px;
    height: 56px;

    @media (max-width: 640px) {
      height: 48px;
    }

    @media (max-width: 433px) {
      font-size: 14px;
    }

    &:hover,
    &:active {
      box-shadow: none;
      background-color: darken($highlight, 8%);
    }
  }
}

.footer-btn {
  font-weight: 500;
  text-decoration: none;

  color: $body;
  background: #f3f5f7;
  box-shadow: none;
  font-family: 'Gotham', sans-serif;
  text-transform: uppercase;
  font-size: 1em;
  border-radius: 0;
  font-weight: 500;
  padding: 24px;
  text-align: left;

  &:hover,
  &:active {
    box-shadow: none;
    background-color: darken(#f3f5f7, 5%);
  }
}

.btn {
  font-weight: 500;

  &.MuiButton-outlined {
    box-shadow: none;
    font-family: Gotham, sans-serif;
    font-weight: 500;
    text-transform: none;
    font-size: 1em;
    font-weight: 500;
    border-radius: 28px;
    padding: 8px 24px;
    border-width: 2px;
    border-color: #000;

    @media (max-width: 433px) {
      font-size: 14px;
    }

    &:hover {
      box-shadow: none;
      background-color: lighten(#fff, 10%);
      border-color: lighten(#000, 75%);
    }
  }
}
