body {
  font-family: 'Sentinel', serif;
  letter-spacing: 0.2px;
  color: $body;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

// *,
// *:after,
// *:before {
//   outline: 1px solid red;
// }

p,
.serif {
  font-family: 'Sentinel', serif;
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Gotham', 'Open Sans', sans-serif;
  font-weight: 900;
}

.main-content {
  margin-top: 80px;

  @media (min-width: 1024px) {
    margin-top: 100px;
  }
}

.title {
  font-size: 48px;
  line-height: 53px;
  margin-bottom: 24px;
  letter-spacing: 0.2px;

  @media (max-width: 960px) {
    font-size: 38px;
    line-height: 43px;
  }

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 41px;
  }
}

.title--medium {
  font-size: 36px;
  line-height: 41px;
  letter-spacing: 0.5px;

  @media (max-width: 960px) {
    font-size: 24px;
    line-height: 29px;
  }

  @media (max-width: 768px) {
    font-size: 21px;
    line-height: 25px;
  }
}

.no-margin {
  margin: 0;
}

.subtitle {
  // font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 50px;
  letter-spacing: 0.2px;

  @media (max-width: 640px) {
    font-size: 18px;
    line-height: 23px;
  }
}

// @media (max-width: 640px) {
//   &.text-center {
//     text-align: left;
//   }
// }

.subtitle--medium {
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.2px;

  // @media (max-width: 960px) {
  //   font-size: 20px;
  //   line-height: 25px;
  // }

  @media (max-width: 768px) {
    font-size: 21px;
    line-height: 25px;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.section {
  margin: 50px 0;

  @media (max-width: 640px) {
    margin: 0;
  }
}

.f-sans-serif {
  font-family: 'Gotham', sans-serif;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: center;
}

@media (max-width: 640px) {
  .xs-text-left {
    text-align: left;
  }
}

.color-primary {
  color: $primary;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 8px;
}

.color-black {
  color: $body;
}

.color-secondary {
  color: $lightgrey;
}

.img-responsive {
  width: 100%;
  max-width: 100%;
}

.divider {
  width: 100%;
  height: 3px;
  background: rgba($color: $lightgrey, $alpha: 0.25);
}

.divider--services {
  margin: 100px 0;

  @media (max-width: 640px) {
    display: none;
  }
}

.link-with-icon {
  display: flex;
  flex-direction: row;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 14px;
  margin: 0 auto;
  display: block;
  margin-top: 48px;

  @media (min-width: 640px) {
    margin-top: 60px;
  }

  @media (min-width: 768px) {
    margin-top: 80px;
    font-size: 18px;
  }

  svg {
    width: 40px;
    position: relative;
    vertical-align: middle;
    margin-top: -2px;
    transition: transform 0.3s ease;
  }

  a {
    color: $primary;
    text-decoration: none;

    &:hover {
      text-decoration: underline;

      svg {
        transform: translateX(4px);
      }
    }
  }
}

.cta-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Gotham', sans-serif;

  @media (min-width: 640px) {
    display: none;
  }
}

.cta-bottom--big-area {
  display: block;
}

p.cta-bottom__heading {
  margin-bottom: 0;
  font-family: 'Gotham', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.cta-bottom__links {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  flex-direction: row;

  a {
    font-weight: bold;
    display: flex;
    align-items: center;

    font-size: 14px;
    color: #fff;

    text-transform: uppercase;
    text-decoration: none;
    margin-right: 16px;

    svg {
      width: 18px;
      height: 18px;
      color: #fff;
    }
  }
}

.cta-bottom--big-area {
  display: block;

  p.cta-bottom__heading {
    @media (min-width: 640px) {
      font-size: 16px;
      margin-top: 26px;
    }
  }

  .cta-bottom__links {
    margin-top: 10px;

    a {
      font-size: 14px;
      color: #fff;

      svg {
        width: 24px;
        height: 24px;
        color: $primary;
      }
    }
  }
}
