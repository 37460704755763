@import '../../styles/colors.scss';

.faq-list {
  padding: 0;
  margin: 0;
}

.faq-list__item {
  padding: 72px 0;

  @media (max-width: 768px) {
    padding: 56px 0;
  }

  @media (max-width: 640px) {
    padding: 32px 0;
  }

  .subtitle {
    margin-bottom: 0;
    padding-right: 32px;
    letter-spacing: 0.5px;

    @media (max-width: 768px) {
      font-size: 21px;
      line-height: 26px;
      margin-top: 0;
    }

    @media (max-width: 480px) {
      font-size: 16px;
      line-height: 21px;
      margin-top: 0;
    }
  }
}

.faq-list__item {
  border-bottom: 3px solid rgba($color: $lightgrey, $alpha: 0.25);

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.faq-list__item {
  svg {
    width: 40px;
    height: 40px;
    color: #000;

    @media (max-width: 768px) {
      width: 32px;
      height: 32px;
    }

    @media (max-width: 480px) {
      width: 24px;
      height: 24px;
    }
  }
}

.faq-list__item__heading {
  margin-top: 0;
  padding-right: 32px;
  cursor: pointer;
}
