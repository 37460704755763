@font-face {
  font-family: 'Sentinel';
  src: url('../assets/fonts/Sentinel-Medium.eot');
  src: url('../assets/fonts/Sentinel-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Sentinel-Medium.svg#Sentinel-Medium') format('svg'),
    url('../assets/fonts/Sentinel-Medium.ttf') format('truetype'),
    url('../assets/fonts/Sentinel-Medium.woff') format('woff'),
    url('../assets/fonts/Sentinel-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Sentinel';
  src: url('../assets/fonts/Sentinel-SemiboldItalic.eot');
  src: url('../assets/fonts/Sentinel-SemiboldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Sentinel-SemiboldItalic.svg#Sentinel-SemiboldItalic')
      format('svg'),
    url('../assets/fonts/Sentinel-SemiboldItalic.ttf') format('truetype'),
    url('../assets/fonts/Sentinel-SemiboldItalic.woff') format('woff'),
    url('../assets/fonts/Sentinel-SemiboldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-Bold.eot');
  src: url('../assets/fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Gotham-Bold.svg#Gotham-Bold') format('svg'),
    url('../assets/fonts/Gotham-Bold.ttf') format('truetype'),
    url('../assets/fonts/Gotham-Bold.woff') format('woff'),
    url('../assets/fonts/Gotham-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-Black.eot');
  src: url('../assets/fonts/Gotham-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Gotham-Black.svg#Gotham-Black') format('svg'),
    url('../assets/fonts/Gotham-Black.ttf') format('truetype'),
    url('../assets/fonts/Gotham-Black.woff') format('woff'),
    url('../assets/fonts/Gotham-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
